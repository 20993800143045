<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getCategoryReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="categoryTitle" class="col-md-10  col-sm-9 col-8 font-weight-normal">
              {{ $t('report.txtbyCategory') }}
            </h2>
            <div class="col-md-2 col-sm-3 col-4 text-right text-success">
              <CDropdown color="info" v-if="isExport" style="width: 100%;">
                <template #toggler>
                <CButton id="categoryExportDropdown" block color="info" >
                  <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                </CButton>
                </template>
                  <CDropdownItem id="categoryExportByCategory" style="width: 100%;"
                  @click="exportCategoryReport()">{{ $t('ByCategory') }}</CDropdownItem>
                  <CDropdownItem id="categoryExportByCategoryDetail" style="width: 100%;"
                  @click="exportCategoryDetailReport()">{{ $t('ByCategoryDetail') }}</CDropdownItem>
                </CDropdown>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <hr />
              <DataTable
                id="categoryDatatable"
              style="white-space: nowrap"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                sorter
                border
              >
              </DataTable>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import DataTable from '@/containers/DataTable'
import Sortdata from '@/containers/SortData'
import permis from '@/util/permission'
import util from '@/util/util'

export default {
  components: {
    DataTable,
    Sortdata,
  },
  data() {
    return {
      data: [],
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      sortdata: 'categoryName',
      sort_value: {
        name: 'categoryName',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          sorter: false,
          _style: 'width:5%',
          _classes: 'text-center text-dark font-weight-normal'
        },
        { key: 'name', label: this.$i18n.t('category'), sorter: false, _classes: 'text-dark font-weight-normal' },
        {
          key: 'grandTotal',
          label: this.$i18n.t('sales'),
          _classes: 'text-right text-dark font-weight-normal',
          sorter: false,
          _style: 'width:15%',
        },
        {
          key: 'quantity',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right text-dark font-weight-normal',
          sorter: false,
          _style: 'width:15%',
        },
      ]
    },
    items() {
      let data = this.data || []
      let detail = []

      for (let i = 0; i < data.length; i++) {
        detail.push({
          id: i + 1,
          name: data[i].categoryName,
          /* grandTotal: data[i].total.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }), */
          grandTotal: util.convertCurrency(data[i].total),
          quantity: data[i].quantity,
        })
      }
      return detail
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'sortByNumMin' || sortdata == 'sortByNumMax') {
          sortdata = 'total'
        }

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'quantity'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
  },
  created() {
    this.getCategoryReport()
  },
  methods: {
    setSort(value) {
      this.sortdata = value
      this.getCategoryReport()
    },
    getCategoryReport() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`

      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      if (this.sortdata == 'sortByNumMin' || this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (
        this.sortdata == 'sortByNumMax' ||
        this.sortdata == 'onhandQtyMax' ||
        this.sortdata == 'PLUCode'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        // sortattr: this.sortAttr,
        // sortorder: sortOrder,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      this.loading = true
      axios({
        url: '/dashboard/v1.0/getcategorysummaryreport/' + uid,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          let data = res.data
          
          this.data = data.data.summary
          
          const paginate = data.paginate || {}
          this.meta_data.items = paginate.items || 0
          this.meta_data.last_page = paginate.pageCount || 0
          this.meta_data.current_page = paginate.currentPage || 0
          this.meta_data.itemCount = paginate.itemCount || 0
          this.meta_data.limit = paginate.perPage || 0
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportCategoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/dashboard/v1.0/getcategorysummaryreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Report by category.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportCategoryDetailReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/categorywithitems/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Report by category with items.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
